import React from 'react';
import ReactDOM from 'react-dom';

import * as serviceWorker from './serviceWorker';

// import App from './containers/App';

import packageJson from '../package.json';
localStorage.removeItem("system");
localStorage.removeItem("config");
localStorage.removeItem("currencyDatabase");
localStorage.removeItem("__localesLoaded");
localStorage.removeItem("formData");

global.appVersion = packageJson.version;

if (process.env.NODE_ENV === 'development') {
    console.log(`
     ____              _    _
    |  _ \\            | |  (_)
    | |_) | ___   ___ | | ___ _ __   __ _
    |  _ < / _ \\ / _ \\| |/ / | '_ \\ / _\` |
    | |_) | (_) | (_) |   <| | | | | (_| |
    |____/ \\___/ \\___/|_|\\_\\_|_| |_|\\__, |
                                     __/ |
                                    |___/
    version ${global.appVersion} - ${process.env.NODE_ENV} stage`
    );
}

const App = React.lazy(() => import("./containers/App"));

ReactDOM.render((
    <React.Suspense fallback={null}>
        <App />
    </React.Suspense>
), document.getElementById('root'));

// ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
